@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family:  "poppins", sans-serif;
}

:root{
    --bg-color:#1f242d;
    --second-bg-color:#323946;
    --text-color:#fff;
    --main-color:#0ef;
}
html{
  font-size: 62.5%;
  overflow-x: hidden;
}
body{
  background: var(--bg-color);
  color: var(--text-color);
}
section{
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}
.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}
.header.sticky {
  border-bottom: .1rem solid rgba(0,0,0,.2) ;
}
.logo{
  font-size: 2.5rem;
  color: var(--text-color);
  font-weight: 600;
  cursor: default;
}
.navbar a{
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
  transition: .3s;
}
.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}
 #menu-icon {
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
 } 
 .home{
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
    
}
.home-content p {
  font-size: medium;
}
.home-content h3:nth-of-type(4){
margin-bottom: 4rem;
}
span{
  color: var(--main-color);
}
.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
}
.home-img img {
width: 35vw;
}
.home-content p {
  font-size: 1.6 rem;
}
.social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border:2rem solid #1f242d;
  border-radius: 50%;
  font-size: 2.5rem;
  color: var(--main-color);
  margin: 3rem 1.5rem 3rem 0;
}

.btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--main-color); 
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--main-color);
  font-size: 1.6rem;
  color: var(--second-bg-color); 
  letter-spacing: .1rem;
  font-weight: 600;
  transition: .5s ease;
}
.btn :hover{
   box-shadow: none;
}
.about{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: var(--second-bg-color);
}
.about-img img{
  width: 35vw;
}
.heading{
  text-align: center;
  font-size: 4.5rem;
}
.about-content h2{
  text-align: center;
  line-height: 1.2;
}
.about-content h3 {
  font-size:2.6rem;
}
.about-content p {
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}
.portfolio{
  background-color: var(--second-bg-color);
}

.portfolio h2 {
  margin-bottom: 4rem;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2rem;
}
.portfolio-container .portfolio-box {
  position: relative;
  border-radius: 2rem;
  box-shadow: 0 0 1rem var(--bg-color);
  overflow: hidden;
  display: flex;
}
.portfolio-box img {
  width: 100%;
  /* transition: .5s ease; */
}
/* .portfolio-box :hover img {
  transform: scale(1.1);
} */
.portfolio-box .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0,0,0,.1), var(--main-color));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  /* transform: translateY(100%);
  transition: .5s ease; */
}
/* .portfolio-box :hover .portfolio-layer{
  transform: translateY(0);
} */
.portfolio-layer h4 {
      font-size: 3rem;

}
.portfolio-layer p {
  font-size: 1.6rem;
  margin: 3 rem 0 1rem;
}
.portfolio-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: var(--text-color);
  border-radius: 50%;
}
.portfolio-layer a i {
  font-family: 2rem;
  color: var(--second-bg-color);
}

.contact h2 {
  margin-bottom: 3rem;

}
.contact form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
  
}
.contact form .input-box{
display: flex;
justify-content:space-between;
flex-wrap: wrap;
}
.contact form .input-box input,
.contact form textarea{
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background: var(--second-bg-color);
  border-radius: .8rem;
  margin: .7rem 0;
}

.contact form .input-box input{
width: 49%;
}
.contact form textarea{
  resize: none;
}
.contact form .btn{
  margin-top: 2rem;
  cursor: pointer;
}
.app{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;
    background: transparent;
    border-radius: 50%;
    font-size: 20rem;
    color: var(--main-color);
    margin: 3rem 1.5rem 3rem 0;
    margin-left: 340px;
  
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background: var(--second-bg-color);
}
.footer-text p {
  font-size: 1.6rem;
}
.footer-iconTop a{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .8rem;
  background: var(--main-color);
  border-radius: .8rem;
  transition: .5s ease;
}
.footer-iconTop a i {
  font-size: 2.4rem;
  color: var(--second-bg-color);
}
@media (max-width: 1200px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }
  section {
    padding: 10rem 3% 2rem;
  }
  .portfolio {
    padding-bottom: 7rem;
  }
  .contact {
    min-height: auto;
  }
  .footer {
    padding: 2rem 3%;
  }
}
@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }
  .navbar {
    position: absolute;
    top: 100%;
    left:0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: 1rem solid rgba(0,0,0,.2);
    box-shadow: 0.5rem 1rem rgba(0,0,0,.2);

  }
  .navbar.active {
    display: block;
  }
  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  
  }
  .home {
    flex-direction: column;
  }
  .home-content h3 {
    font-size: 2.6rem;
  }
  .home-content h1 {
    font-size: 5rem;
  }
  .home-img img {
     width: 70vw;
     animation: floatImage 4s ease-in-out infinite;
  }
  @keyframes floatImage {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-2.4rem);
    }
    100% {
      transform: translateY(0);
    }
  }
  .about {
    flex-direction: column-reverse;
  }
  .about img {
    width: 70vw;
    margin-top: 4rem;
  }
  .portfolio-container h2 {
    margin-bottom: 3rem;

  }
  .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 617px) {
  .portfolio-container {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
  .contact form .input-box input {
    width: 100%;
  }
}
@media (max-width: 365px) {
  .home-img img {
    width: 90vw;
  }
  .about-img img {
    width: 90vw;
  }
  .footer {
    flex-direction: column-reverse;
  }
  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}